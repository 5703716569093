import React from 'react';
import './Services.css';
import basic from './images/basic.svg'
import state from './images/state.svg'
import central from './images/central.svg'
import serviceImage from './images/IBM-New-License-Measurement-Methodology-header-jpg-1670327798489.jpg'

const Services = () => {
  const serviceCategories = [
    'FSSAI Food Safety License Registration',
    'FSSAI Basic License Registration',
    'FSSAI State License Registration',
    'FSSAI Central License Registration',
    'FSSAI Central License Renewal',
    'FSSAI Basic License Renewal',
    'FSSAI State License Renewal',
    'FSSAI for Hotels',
    'FSSAI for Restaurant',
    'FSSAI for Supermarket',
    'FSSAI for Zomato',
    'FSSAI for Swiggy',
    'FSSAI for Fish Retailers',
    'FSSAI for Fish Exporters',
    'FSSAI for Agricultural Products',
    'FSSAI for Chips Manufacturers',
    'FSSAI for Coconut Oil Manufacturers',
    'FSSAI for Olive oil Manufacturers',
    'FSSAI for School',
    'FSSAI for E-Commerce',
    'FSSAI for Pizza & Sandwich Outlets',
    'FSSAI for Spices Company',
    'FSSAI for Bar & Beverages',
    'FSSAI for Coffee Shop',
    'APEDA License Registration for Agricultural Products',
    'FSSAI for Small Food Manufacturing Unit',
    'FSSAI for Uber Eats',
    'FSSAI for Amazon Sellers',
    'FSSAI for Foodpanda',
    'FSSAI for Flipkart Sellers',
    'FSSAI for Online Sellers',
    'FSSAI for Packaged Drinking Water Unit',
    'FSSAI for Juice Shop',
    'FSSAI for Cake Manufacturers',
    'FSSAI for Hospitals',
    'FSSAI for Food Manufacturers',
    'FSSAI for Food Importers & Exporters',
    'FSSAI for Corporate Companies',
    'FSSAI for Sweet Manufacturers',
    'FSSAI for Vegetable Dealers',
    'FSSAI for Fruits Dealers',
    'FSSAI for Dairy Product Manufacturers',
    'FSSAI for Vegetables Importers',
    'FSSAI for Pharmaceutical Company',
    'FSSAI for Honey',
    'FSSAI for Fruits Importers',
    'FSSAI for Vehicle Food Business',
    'FSSAI for Catters',
    'FSSAI for Events Management',
    'FSSAI for College',
    'FSSAI for Milk Chilling Units',
    'FSSAI for Grocery',
    'FSSAI for Chocolate Manufacturers',
    'FSSAI for Vegetables Exporters',
    'FSSAI for Fruits Exporters',
    'FSSAI for Home Based Food Business',
    'FSSAI for Bakery Products',
    'FSSAI for Meat Shop',
    'FSSAI for Mess',
    'FSSAI for Canteen',
    'FSSAI for Food Transporters',
    'FSSAI for Hypermarket',
    'FSSAI for Repackers',
    'FSSAI for Food Business Operators - FBO',
    'FSSAI for Medical Shop',
    'FSSAI Food Safety Registration in India',
    'FSSAI Food Safety Registration in Andaman and Nicobar (AN)',
    'FSSAI Food Safety Registration in Andhra Pradesh (AP)',
    'FSSAI Food Safety Registration in Arunachal Pradesh (AR)',
    'FSSAI Food Safety Registration in Assam',
    'FSSAI Food Safety Registration in Bihar',
    'FSSAI Food Safety Registration in Chhattisgarh',
    'FSSAI Food Safety Registration in Dadra and Nagar Haveli - Silvassa',
    'FSSAI Food Safety Registration in Daman and Diu',
    'FSSAI Food Safety Registration in Delhi',
    'FSSAI Food Safety Registration in Goa',
    'FSSAI Food Safety Registration in Gujarat',
    'FSSAI Food Safety Registration in Haryana',
    'FSSAI Food Safety Registration in Himachal Pradesh',
    'FSSAI Food Safety Registration in Jammu and Kashmir',
    'FSSAI Food Safety Registration in Jharkhand',
    'FSSAI Food Safety Registration in Karnataka',
    'FSSAI Food Safety Registration in Kerala',
    'FSSAI Food Safety Registration in Lakshadweep',
    'FSSAI Food Safety Registration in Madhya Pradesh',
    'FSSAI Food Safety Registration in Maharashtra',
    'FSSAI Food Safety Registration in Manipur',
    'FSSAI Food Safety Registration in Mizoram',
    'FSSAI Food Safety Registration in Nagaland',
    'FSSAI Food Safety Registration in Odisha',
    'FSSAI Food Safety Registration in Pondicherry',
    'FSSAI Food Safety Registration in Punjab',
    'FSSAI Food Safety Registration in Rajasthan',
    'FSSAI Food Safety Registration in Sikkim',
    'FSSAI Food Safety Registration in Tamil Nadu',
    'FSSAI Food Safety Registration in Telangana',
    'FSSAI Food Safety Registration in Tripura',
    'FSSAI Food Safety Registration in Uttar Pradesh',
    'FSSAI Food Safety Registration in Uttarakhand',
    'FSSAI Food Safety Registration in West Bengal',
  ];

  return (
    
  <>
   <header className="service-header">
        <img src={serviceImage} alt="Contact Us" className="header-image" />
        <p className="header-text">We Provide All Type of food License</p>
      </header>
    <div className="services-container">
      
        <p className='wwp'>What We Provide</p>
      <div className="services-list">
      
        {serviceCategories.map((service, index) => (
          <div key={index} className="service-item">
            <h2>{service}</h2>
          </div>
        ))}
      </div>
      <section className="fssai-license-types">
        <h2 className="section-title">Types of FSSAI License</h2>
        <p className="section-description">
          Based on the annual turnover and the number of locations of the food business, three types of food licenses are provided.
        </p>
        <div className="license-cards">
          <div className="license-card">
            <img src={basic} alt="Basic Registration" className="license-icon" />
            <h3>FSSAI Basic Registration</h3>
            <p>
              If the food business owner generates an annual turnover that is less than ₹12 lakhs, they shall get registered through basic registration.
            </p>
          </div>
          <div className="license-card">
            <img src={state} alt="State License" className="license-icon" />
            <h3>FSSAI State License</h3>
            <p>
              If the food business has an annual turnover between ₹12 lakhs and ₹20 crores, then the food business operator should apply for a state license.
            </p>
          </div>
          <div className="license-card">
            <img src={central} alt="Central License" className="license-icon" />
            <h3>FSSAI Central License</h3>
            <p>
              If the annual revenue of the food business is above ₹20 crores, then the food business owner should apply for a central license.
            </p>
          </div>
        </div>
      </section>

      <section className="fssai-info">
        <h2 className="section-title">Who Need FSSAI Food Safety License</h2>
        <p className="section-description">
          The primary step for FSSAI Food safety Registration and Licensing is identifying the nature of business structure.
          There are various kinds of businesses like Manufacturer, Repacker, Retailer, Distributor, Supplier, Marketer,
          Importer, Exporter, E-commerce, Wholesaler, Restaurant, Canteen, Mess, Food vending establishment, Food vending
          agencies, Petty Retailer for Tea and Snacks.
        </p>
       
      </section>
    </div>
  </>
  );
};

export default Services;
