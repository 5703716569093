import React from 'react';
import './Home.css';
import basic from './images/basic.svg'
import state from './images/state.svg'
import central from './images/central.svg'
import Header from './Header';
import {Link} from 'react-router-dom'

const Home = () => {
  return (
    <div className="home">
      <div className="hero-section">
     
        <h1>Welcome to Food License Consultancy</h1>
        <p>Your one-stop solution for all food licensing requirements.</p>
        <div className='buttons'>
        <Link to="/fssairegister" className="cta-button">New FSSAI Registration</Link>
        <Link to="/renewform" className="ca-button">Renewal of FSSAI Registration</Link>
        </div>
        
      </div>
      
      <section className="fssai-license-types">
        <h2 className="section-title">Types of FSSAI License</h2>
        <p className="section-description">
          Based on the annual turnover and the number of locations of the food business, three types of food licenses are provided.
        </p>
        <div className="license-cards">
          <div className="license-card">
            <img src={basic} alt="Basic Registration" className="license-icon" />
            <h3>FSSAI Basic Registration</h3>
            <p>
              If the food business owner generates an annual turnover that is less than ₹12 lakhs, they shall get registered through basic registration.
            </p>
          </div>
          <div className="license-card">
            <img src={state} alt="State License" className="license-icon" />
            <h3>FSSAI State License</h3>
            <p>
              If the food business has an annual turnover between ₹12 lakhs and ₹20 crores, then the food business operator should apply for a state license.
            </p>
          </div>
          <div className="license-card">
            <img src={central} alt="Central License" className="license-icon" />
            <h3>FSSAI Central License</h3>
            <p>
              If the annual revenue of the food business is above ₹20 crores, then the food business owner should apply for a central license.
            </p>
          </div>
        </div>
      </section>

      <section className="fssai-info">
        <h2 className="section-title">Who Need FSSAI Food Safety License</h2>
        <p className="section-description">
          The primary step for FSSAI Food safety Registration and Licensing is identifying the nature of business structure.
          There are various kinds of businesses like Manufacturer, Repacker, Retailer, Distributor, Supplier, Marketer,
          Importer, Exporter, E-commerce, Wholesaler, Restaurant, Canteen, Mess, Food vending establishment, Food vending
          agencies, Petty Retailer for Tea and Snacks.
        </p>
        
      </section>
    </div>
  );
};

export default Home;
