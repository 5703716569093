// src/components/AboutUs.jsx
import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="about-us-hero">
        <div className="hero-content">
          <h1>About Us</h1>
        </div>
      </section>

      <section className="about-us-content">
        <h2>Welcome to FoodMitra</h2>
        <p>Welcome to FoodMitra, your trusted partner in securing food licenses and ensuring regulatory compliance in the food industry.</p>
        <p>We're a cutting-edge consultancy specializing in food license acquisition, simplifying regulations so food entrepreneurs can focus on their craft. Our expertise ensures hassle-free compliance, empowering culinary innovators to bring their creations to life.</p>
        <p>
          We are dedicated to helping businesses navigate the complex world of food safety regulations, providing comprehensive services to obtain the necessary licenses and certifications that allow you to operate with confidence and integrity.
        </p>

        <h2>Our Mission</h2>
        <p>
          At FoodMitra, our mission is to simplify the process of acquiring food licenses, enabling food businesses of all sizes to focus on what they do best—creating and serving quality food. We are committed to ensuring that your business meets all legal requirements, safeguarding public health while helping you build trust with your customers.
        </p>
      </section>

      <section className="fssai-info">
        <h2 className="section-title">Who Needs an FSSAI Food Safety License?</h2>
        <p className="section-description">
          The primary step for FSSAI Food safety Registration and Licensing is identifying the nature of business structure.
          There are various kinds of businesses like Manufacturer, Repacker, Retailer, Distributor, Supplier, Marketer,
          Importer, Exporter, E-commerce, Wholesaler, Restaurant, Canteen, Mess, Food vending establishment, Food vending
          agencies, Petty Retailer for Tea and Snacks.
        </p>
      </section>

      {/* New Benefits Section */}
      <section className="benefits-section">
        <h2>Benefits of Having a Food License</h2>
        <ul className="benefits-list">
          <li><strong>Legal Protection:</strong> It provides protection against legal actions from regulatory authorities, ensuring that your business complies with the law.</li>
          <li><strong>Customer Trust:</strong> Holding an FSSAI license builds customer confidence and shows your commitment to food safety and hygiene standards.</li>
          <li><strong>Business Expansion:</strong> It opens doors to business expansion opportunities, enabling you to operate across different regions with ease.</li>
          <li><strong>Brand Reputation:</strong> A certified license enhances your brand's reputation and helps attract new customers, partners, and investors.</li>
          <li><strong>Ease of Exporting:</strong> An FSSAI license is a vital requirement if you're looking to export food products, ensuring global compliance.</li>
          <li><strong>Government Benefits:</strong> Licensed businesses often have access to various government schemes and benefits that are otherwise unavailable to unregistered entities.</li>
        </ul>
      </section>
    </div>
  );
};

export default AboutUs;
