import logo from './logo.svg';
import './App.css';
import Header from './Header';
import{ BrowserRouter,Routes,Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Home from './Home';
import Services from './Services';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Blogs from './Blogs';
import FassaiBasic from './FassaiBasic';
import FassaiState from './FassaiState';
import FasaaiCentral from './FasaaiCentral';
import FasaiRegistration from './FasaiRegistration';
import RenwaForm from './RenwaForm';
function App() {
  return (
    <div >
       <BrowserRouter>
            <Header/>
            <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/about" element={<AboutUs/>} />
            <Route path="/blogs" element={<Blogs/>} />
            <Route path="/contact" element={<ContactUs/>} />
            <Route path="/basic" element={<FassaiBasic/>} />
            <Route path="/state" element={<FassaiState/>} />
            <Route path="/central" element={<FasaaiCentral/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/fssairegister" element={<FasaiRegistration/>} />
            <Route path="/renewform" element={<RenwaForm/>} />
            </Routes>
        <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
