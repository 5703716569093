// src/components/Footer.jsx
import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from './images/1.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="Company Logo" />
          <p>Providing Food Safety Licenses Across India</p>
        </div>
        <div className="footer-links">
          <h2>Useful Links</h2>
          <ul>
            <li><Link to="/fasiregister">FSSAI Food Safety License Registration</Link></li>
            <li><Link to="/renewform">Renewal of FSSAI Existing Lisence</Link></li>
            <li><Link to="/fasiregister">FSSAI for Restaurant</Link></li>
            <li><Link to="/fasiregister">FSSAI for Supermarket</Link></li>
            {/* Add more links as needed */}
          </ul>
        </div>
        <div className="footer-contact">
          <h2>Contact Us</h2>
          <p>Office no G28, Second floor, Mansing market , above white House , Golani Market, Jalgaon 425001</p>
          <p>Phone:+91 7030226191</p>
          <p>Email: info@foodlicense.com</p>
        </div>
        <div className="footer-social">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="#"><FaFacebookF /></a>
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaLinkedinIn /></a>
            <a href="#"><FaInstagram /></a>
          </div>
        </div>
        <div className="footer-cta">
          <a href="/fasiregister" className="button">Get Your License Now</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
